


































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { getDicts } from '@/utils/dict'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

@Component({
  components: {
    Table,
    Pagination
  }
})
export default class OwnerCheck extends Vue {
  $constants: any
  // 业主审核数
  num1: any = '0'
  // 业主解绑房屋审核数
  num2: any = '0'
  // 区域字典
  auditDicts: any = []
  //证件类型字典
  cardDicts: any = []
  tabActive = 0
  tabData = [
    {
      value: '1',
      label: `业主审核（${this.num1}）`
    },
    {
      value: '2',
      label: `业主解绑房屋审核（${this.num2}）`
    }
  ]
  searchModel = {
    key: 'name',
    value: null
  }
  state = ''
  listLoading = false
  tableHeader = [
    {
      key: 'name',
      name: '姓名',
      width: '8%'
    },
    {
      key: 'mobile',
      name: '手机号',
      width: ''
    },
    {
      key: 'phase',
      name: '区域',
      width: '8%'
    },
    {
      key: 'buildingNo',
      name: '房屋',
      width: '8%'
    },
    {
      key: 'cardType',
      name: '证件类型',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'cardNo',
      name: '证件号码',
      width: '16%'
    },
    {
      key: 'auditStatus',
      name: '审核状态',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'submitTime',
      name: '提交时间',
      width: '14%'
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  auditType: any = 1 //1业主审核  2解绑审核
  async beforeCreate() {
    // 获取相关字典
    // 审核状态
    this.auditDicts = await getDicts('AUDIT_STATUS')
    // 证件类型
    this.cardDicts = await getDicts('CARD_TYPE')
  }
  //
  created() {
    this.auditType = this.$route.query.auditType || 1
    this.tabActive = Number(this.auditType - 1)
    this.getOwnerCheckList()
    this.getAuditCount()
  }
  //查询
  searchClick() {
    this.listQuery.page = 1
    this.listQuery.limit = 15
    this.getOwnerCheckList()
  }
  //获取业主待审核/解绑待审核数量
  getAuditCount() {
    this.listLoading = true
    this.$api.resident
      .getAuditCount()
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        if (data && data.success) {
          this.tabData[0].label = '业主审核（' + data.data.onwerCount + '）'
          this.tabData[1].label =
            '业主解绑房屋审核（' + data.data.unbindCount + '）'
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }
  // 获取业主审核列表
  getOwnerCheckList() {
    this.listLoading = true
    this.$api.resident
      .getAuditList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        auditStatus: this.state,
        auditType: this.auditType,
        [this.searchModel.key]: this.searchModel.value
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        if (data && data.success) {
          this.tableData = data.data
          this.total = data.total
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  tabChange(index: number) {
    this.tabActive = index
    this.auditType = index == 0 ? 1 : 2
    this.getOwnerCheckList()
    this.getAuditCount()
  }
  //审核状态
  stateFilter(type: string) {
    if (this.auditDicts && typeof type !== undefined) {
      return this.auditDicts.find((x: any) => x.value == type).name
    }
  }
  //证件类型
  cardTypeFilter(type: number) {
    if (this.cardDicts && typeof type !== 'undefined') {
      const res = this.cardDicts.find((x: any) => x.value == type + '')
      return res && res.name
    }
  }

  toDetail(item: any) {
    this.$router.push('/residents/owner-check-detail?id=' + item.id)
  }
}
